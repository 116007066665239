import http from '@/http-common'

const URL = '/api/azure'

class AzureService {
  getUsers() {
    return http.get(`${URL}/users`)
  }
  addUser(user) {
    return http.post(`${URL}/add`, {'id': user.id, 'email': user.userPrincipalName, 'name': user.displayName, 'location': user.usageLocation})
  }
  livesearch(query, field) {
    return http.get(`${URL}/users?search=${query}&field=${field}`)
  }
}

export default new AzureService()